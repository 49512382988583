import React from 'react';
import { Link } from 'gatsby';

import Container from '../components/Container';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

const H3 = (props) => <h3 className="mt-8 mb-4 text-lg font-bold" {...props} />;
const H4 = (props) => <h4 className="font-bold" {...props} />;

const P = (props) => <p className="mb-4" {...props} />;

const UL = (props) => <ul className="pl-8 mb-4 list-disc" {...props} />;

const A = (props) => <Link className="border-b" {...props} />;

const PrivacyPage = () => {
  return (
    <Layout>
      <SEO title="Privacy" description="Read our privacy policy." />
      <Container className="p-4 my-8 bg-white rounded-lg privacy-content">
        <h2 className="mt-8 mb-4 text-2xl font-bold">Wolvido Privacy Policy</h2>
        <section>
          <H3>1. Important information and who we are</H3>

          <P>
            Wolvido is part of the company “WIT Interactive LLC” registered for
            business in US.
          </P>

          <H4>Purpose of this privacy policy</H4>

          <P>
            This privacy policy gives you information about how Wolvido collects
            and processes your personal data when you use our services,
            including any personal data you may provide through the Wolvido
            platform and on our websites. It also includes information about
            your privacy rights.
          </P>

          <P>
            This privacy policy applies to all users of the Wolvido platform and
            websites, online video hosting and related services at{' '}
            <A to="/">https://wolvido.com</A> including subdomains to the extent
            applicable under law (the “<strong>Services</strong>”).
          </P>

          <H4>Changes to our privacy policy</H4>

          <P>
            We keep our privacy policy under regular review and it may be
            updated periodically. Laws, regulations, and industry standards
            evolve, which may make those changes necessary, or we may make
            changes to our Services or business. We will notify you by posting
            an updated, date-stamped version of this privacy policy on our
            website. If we make changes that materially alter the privacy
            policy, we will provide notice to you in accordance with applicable
            law.
          </P>

          <P>
            Where other language versions of this Wolvido Privacy Policy exist,
            the English version will prevail in the event of any conflict and/or
            confusion between the documents.
          </P>

          <H4>Third party websites, applications, and integrations.</H4>

          <P>
            The Services may include links to third-party websites and
            applications as well as to integration and partner providers.
          </P>

          <P>
            Clicking on links to third party websites, content, applications, or
            integrations may allow those third parties to collect or share data
            about you. We do not control those third-party websites or content
            and are not responsible for their privacy statements or the way in
            which they collect or use your data.
          </P>

          <P>
            Where you access or use such third party services or integrations,
            we encourage you to request details of and read the privacy policy
            of that party.
          </P>
        </section>

        <section>
          <H3>2. The personal data we collect about you</H3>

          <P>
            We may collect and process different kinds of personal data about
            you which we have grouped together as follows:
          </P>

          <UL>
            <li>
              <strong>Identity Data</strong> where this is requested, includes
              your image and first name, maiden name, last name, username or
              similar identifier, title, age and gender.
            </li>
            <li>
              <strong>Profile Data</strong> which includes your username and
              password, purchases or orders made by you, preferences, feedback
              and survey responses.
            </li>
            <li>
              <strong>Transaction Data</strong> which includes details about
              payments you have made and other details of products and services
              you have accessed or used through the Services. We do not store
              card details on our server. Credit and debit card payments are
              processed by Stripe on their secure payments server and all card
              details are fully encrypted and stored by them.
            </li>
            <li>
              <strong>Technical Data</strong> which includes your internet
              protocol (IP) address, your login data, browser type and version,
              hardware information, time zone setting and location, browser
              plug-in types and versions, operating system and website, and
              other technology on the devices you use to access the Services.
            </li>
            <li>
              <strong>Usage Data</strong> which includes information about how
              you use the Services, as well as third-party platforms which you
              have linked to your use of the Services. Usage data includes
              metadata which provides additional context about the way the
              Services are being used such as length of visit, page views,
              navigation paths, and page interaction information (such as
              scrolling, clicks, mouse-overs, and mouse tracking) as well as
              information about the timing, frequency and pattern of use.
            </li>
            <li>
              <strong>Marketing and Communications Data</strong> which includes
              your preferences in receiving marketing from us and our third
              party partners and your communication preferences.
            </li>
            <li>
              <strong>User Generated Content</strong> which includes videos,
              text, photographs and artwork you upload as part of your
              interactions with the Services. Your User Generated Content may be
              viewed by others.
            </li>
          </UL>

          <H4>Children</H4>

          <P>
            We do not knowingly collect or process personal data from children,
            and no part of our Services is directed to them. A “child” is a
            person (a) under 13 years old (for residents outside of the EEA/UK,
            except for the Republic of Korea); (b) under 16 years old or such
            age needed to consent to the processing of personal data in their
            country of residence (for residents of the EEA/UK); or (c) under 14
            years old (for residents of the Republic of Korea). If you are a
            parent or guardian and you learn that your child has provided us
            with personal data, please contact us. If we become aware that we
            have collected personal data from a child without verification of
            parental consent, we take steps to remove that information from our
            systems.
          </P>
        </section>

        <section>
          <H3>3. How is your personal data collected?</H3>

          <P>
            We use different methods to collect data from and about you
            including through:
          </P>

          <UL>
            <li>
              <strong>Direct interactions.</strong> You may give us your Profile
              Data and other information by filling in forms or by corresponding
              with us by email or otherwise. This includes personal data you
              provide when you: (a) create an account with us; (b) subscribe (or
              are subscribed) to the Services; (c) interact in the Services; (d)
              request marketing to be sent to you; (d) enter a promotion or
              survey; or (e) give us feedback or contact us.
            </li>
            <li>
              <strong>Automated technologies or interactions.</strong> As you
              interact with the Services, we will automatically collect
              Technical Data about your equipment, browsing actions and
              patterns. We also collect Usage Data via cookies, server logs and
              other similar technologies which track your interaction with the
              Services.
            </li>
            <li>
              <strong>Third-Parties.</strong> Depending on your use of the
              Services, we may collect data about you from third-party service
              providers which you have linked to your use of the Services
              (including social media account and single-sign-on services),
              publicly available sources, data enrichment vendors, payment and
              delivery service vendors, advertising networks, analytics
              providers (such as Google Analytics), and our business partners.
              The information which we receive from third parties depends on
              what policies that third party follows and our and your respective
              relationships with the third party.
            </li>
          </UL>
        </section>

        <section>
          <H3>4. How we use your personal data</H3>

          <P>
            We have set out below, in a table format, a description of how and
            why we use your personal data, the categories of data used for each
            purpose and which of the legal bases we rely on to do so. We have
            also identified what our legitimate interests are where appropriate.
          </P>

          <table className="mb-4">
            <thead>
              <tr>
                <th>Purpose/Activity</th>

                <th>Type of data</th>

                <th>
                  Lawful basis for processing including basis of legitimate
                  interest
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  To register you as a user of the Services and manage payments
                  if applicable
                </td>

                <td>
                  <ol>
                    <li>Identity</li>
                    <li>Technical</li>
                    <li>Profile</li>
                    <li>Transaction</li>
                  </ol>
                </td>
                <td>Performance of a contract with you</td>
              </tr>
              <tr>
                <td>
                  To manage our relationship with you which will include:
                  <br />
                  (a) Providing access to the Services and information about
                  your interaction with us
                  <br />
                  (b) Notifying you about changes to our terms or privacy policy
                  <br />
                  (c) Asking you to leave a review or take a survey
                  <br />
                  (d) Investigating complaints
                </td>
                <td>
                  <ol>
                    <li>Identity</li>
                    <li>Contact</li>
                    <li>Profile</li>
                    <li>Marketing and Communications</li>
                  </ol>
                </td>
                <td>
                  (a) Performance of a contract with you
                  <br />
                  (b) Necessary to comply with a legal obligation
                  <br />
                  (c) Necessary for our legitimate interests (to keep our
                  records updated and to study how users use the Services)
                </td>
              </tr>
              <tr>
                <td>
                  To administer and protect our business and our Services
                  (including troubleshooting, data analysis, testing, product /
                  service improvement / development / analysis, system
                  maintenance, support, reporting and hosting of data,
                  compliance with legal and/or regulatory obligations, enforcing
                  our terms and guidelines)
                </td>
                <td>
                  <ol>
                    <li>Identity</li>
                    <li>Technical</li>
                    <li>Profile</li>
                    <li>Usage</li>
                    <li>User-generated content</li>
                  </ol>
                </td>
                <td>
                  (a) Necessary for our legitimate interests (for running our
                  business, improving / developing / analysis our products /
                  services, administering our CRM, provision of administration
                  and IT services, network security, to prevent fraud and in the
                  context of a business reorganisation or group restructuring
                  exercise)
                  <br />
                  (b) Necessary to comply with a legal obligation
                </td>
              </tr>
              <tr>
                <td>
                  To enable you to partake in a competition or complete a survey
                </td>
                <td>
                  <ol>
                    <li>Identity</li>
                    <li>Profile</li>
                    <li>Usage</li>
                    <li>Marketing and Communications</li>
                  </ol>
                </td>
                <td>
                  (a) Performance of a contract with you
                  <br />
                  (b) Necessary for our legitimate interests (to study how users
                  use the Services and to develop and grow our business)
                </td>
              </tr>
              <tr>
                <td>
                  To deliver relevant content and advertisements to you and
                  measure or understand the effectiveness of the advertising we
                  serve to you
                </td>
                <td>
                  <ol>
                    <li>Identity</li>
                    <li>Profile</li>
                    <li>Usage</li>
                    <li>Marketing and Communications</li>
                    <li>Technical</li>
                  </ol>
                </td>
                <td>
                  (a) Necessary for our legitimate interests (to study how users
                  use the Services and to grow our business and inform our
                  marketing and growth strategy)
                  <br />
                  (b) Consent
                </td>
              </tr>
              <tr>
                <td>
                  To use data analytics to improve our products/services,
                  marketing, user and partner relationships and experiences
                </td>
                <td>
                  <ol>
                    <li>Identity</li>
                    <li>Contact</li>
                    <li>Technical</li>
                    <li>Profile</li>
                    <li>Usage</li>
                  </ol>
                </td>
                <td>
                  Necessary for our legitimate interests (to define types of
                  users for certain Services and to keep our Services and web
                  site updated and relevant, to develop our business and to
                  inform our product and marketing strategy)
                </td>
              </tr>
              <tr>
                <td>
                  To make suggestions and recommendations to you about events or
                  other services available through the Services that may be of
                  interest to you
                </td>
                <td>
                  <ol>
                    <li>Identity</li>
                    <li>Contact</li>
                    <li>Technical</li>
                    <li>Profile</li>
                    <li>Usage</li>
                    <li>Marketing and Communications</li>
                  </ol>
                </td>
                <td>
                  Necessary for our legitimate interests (to develop the
                  products and Services available through the Services)
                </td>
              </tr>
            </tbody>
          </table>

          <H4>Cookies</H4>

          <P>
            We use various technologies to collect information, and this may
            include sending cookies to your computer or mobile device. Cookies
            are small data files stored on your hard drive or in device memory
            that help us to improve the Services and your experience, see which
            areas and features of the Services are popular and count visits.
          </P>

          <P>
            We may also collect information using web beacons (also known as
            “tracking pixels”). Web beacons are electronic images that may be
            used in the Services or emails and help deliver cookies, count
            visits, understand usage and campaign effectiveness and determine
            whether an email has been opened and acted upon.
          </P>

          <P>
            Most web browsers are set to accept cookies by default. If you
            prefer, you can choose to set your browser to remove or reject
            browser cookies. Please note that if you choose to remove or reject
            cookies, this could affect the availability and functionality of the
            Services.
          </P>

          <H4>Advertising and Analytics Services Provided by Others</H4>

          <P>
            We may allow others to serve advertisements on our behalf across the
            internet and to provide analytics services. These entities may use
            cookies, web beacons and other technologies to collect information
            about your use of the Services and other websites, including your IP
            address, web browser, pages viewed, time spent on pages, links
            clicked and conversion information.
          </P>

          <P>
            This information may be used by Wolvido and others to, among other
            things, analyze and track data, determine the popularity of certain
            content, deliver advertising and content targeted to your interests
            on the Services and other websites and better understand your online
            activity.
          </P>

          <P>
            For more information about Internet-based ads, or to opt out of
            having your web browsing information used for behavioral advertising
            purposes, please visit{' '}
            <a
              href="https://www.aboutads.info/choices"
              className="border-b"
              target="_blank"
              rel="noreferrer"
            >
              www.aboutads.info/choices
            </a>
            .
          </P>
        </section>
        <section>
          <H3>5. Disclosures of your personal data</H3>

          <P>
            We will share your personal data with the following recipients to
            achieve the purposes set out in the table “Purposes for which we
            will use your personal data” above.
          </P>

          <H4>Service providers</H4>

          <UL>
            <li>
              Service providers acting as processors such as those who provide
              our IT and system administration services.
            </li>
            <li>Providers of our cloud services such as AWS and Google.</li>
            <li>
              Stripe – for the purposes of payment transaction management.{' '}
              <a
                href="https://stripe.com/gb/privacy"
                target="_blank"
                rel="noreferrer"
                className="border-b"
              >
                Stripe Privacy Terms
              </a>
            </li>
            <li>
              Paypal – for the purposes of payment transaction management.{' '}
              <a
                href="https://www.paypal.com/webapps/mpp/ua/privacy-full"
                target="_blank"
                rel="noreferrer"
                className="border-b"
              >
                Paypal Privacy Terms
              </a>
            </li>
            <li>
              Our third-party partners, integration providers, and affiliates
              whom you engage with in order to facilitate the provision of the
              Services.
            </li>
          </UL>

          <H4>Other Third Parties</H4>

          <UL>
            <li>
              Professional advisers including lawyers, bankers, auditors and
              insurers who provide consultancy, banking, legal, insurance and
              accounting services.
            </li>
            <li>
              Regulators and other public authorities including law enforcement
              agencies where required by law.
            </li>
            <li>Our affiliates.</li>
            <li>
              In connection with, or during negotiations of, any merger, sale or
              transfer of company assets, financing or acquisition of all or a
              portion of our business. If a change happens to our business, we
              will notify you.
            </li>
          </UL>
        </section>
        <section>
          <H3>6. International transfers</H3>

          <P>
            We will transfer your personal data outside your country of
            residence in our performance of the Services. When we transfer
            personal data out of the EEA or the UK, we ensure an adequate degree
            of protection is afforded to it by implementing safeguards.
          </P>
        </section>
        <section>
          <H3>7. Data security</H3>

          <P>
            We have put in place appropriate technical and organizational
            security measures to prevent your personal data from being
            accidentally lost, used or accessed in an unauthorized way, altered
            or disclosed.
          </P>

          <P>
            In addition, we limit access to your personal data to those
            employees, agents, contractors and other third parties who have a
            business need to know. That said, no method of electronic
            transmission or storage is 100% secure and we cannot guarantee
            absolute data security.
          </P>
        </section>
        <section>
          <H3>8. Data retention</H3>

          <P>
            We will only retain your personal data for as long as reasonably
            necessary to fulfill the purposes we collected it for, including for
            the purposes of satisfying any legal, regulatory, tax, accounting or
            reporting requirements. We may retain your personal data for a
            longer period in the event of a complaint, if we reasonably believe
            there is a prospect of litigation in respect to our relationship
            with you, or as required or permitted by applicable law.
          </P>

          <P>
            To determine the appropriate retention period for personal data, we
            consider the amount, nature and sensitivity of the personal data,
            the potential risk of harm from unauthorized use or disclosure of
            your personal data, the purposes for which we process your personal
            data and whether we can achieve those purposes through other means,
            and the applicable legal, regulatory, tax, accounting or other
            requirements.
          </P>
        </section>
        <section>
          <H3>9. Your privacy rights</H3>

          <P>
            Under certain circumstances, you have rights under data protection
            laws in relation to your personal data. You have the right to:
          </P>

          <UL>
            <li>
              <strong>Request access</strong> to your personal data (commonly
              known as a “subject access request”). This enables you to receive
              a copy of the personal data we hold about you and certain
              information to check that we are lawfully processing it.
            </li>
            <li>
              <strong>Request correction</strong> of the personal data that we
              hold about you. This enables you to have any incomplete or
              inaccurate data we hold about you corrected, though we may need to
              verify the accuracy of the new data you provide to us.
            </li>
            <li>
              <strong>Request erasure</strong> of your personal data. This
              enables you to ask us to delete or remove personal data where
              there are valid grounds for doing so and subject to applicable
              law.
            </li>
            <li>
              <strong>Request restriction</strong> of processing of your
              personal data. This enables you to ask us to suspend the
              processing of your personal data in the following scenarios:
              <UL style={{ 'list-style-type': 'circle' }}>
                <li>If you want us to establish the data’s accuracy.</li>
                <li>
                  Where our use of the data is unlawful but you do not want us
                  to erase it.
                </li>
                <li>
                  Where you need us to hold the data even if we no longer
                  require it as you need it to establish, exercise or defend
                  legal claims.
                </li>
                <li>
                  You have objected to our use of your data but we need to
                  verify whether we have overriding legitimate grounds to use
                  it.
                </li>
              </UL>
            </li>
            <li>
              <strong>Right to data portability.</strong> You may have the right
              to receive certain of your information in a structured, commonly
              used and machine-readable format and to transmit such information
              to another controller.
            </li>
            <li>
              <strong>Object to processing</strong> of your personal data where
              we process your data based on legitimate interest. We will assess
              your objection and determine whether we have any compelling
              legitimate grounds or legal justification for continued
              processing.
            </li>
            <li>
              <strong>Withdraw consent</strong> at any time where we are relying
              on consent to process your personal data. However, this will not
              affect the lawfulness of any processing carried out before you
              withdraw your consent.
            </li>
            <li>
              <strong>Right to complain.</strong> You have the right to make a
              complaint to your local data protection authority.
            </li>
          </UL>

          <P>
            If you wish to exercise any of the rights set out above, please
            contact us as set forth in the “Contacting Us” section below.
          </P>
        </section>
        <section>
          <H3>10. Contacting Us</H3>

          <P>
            If you have any questions about this privacy policy or our privacy
            practices, including any requests to exercise your legal rights,
            please contact us at:{' '}
            <a href="mailto:support@wolvido.com" className="border-b">
              support@wolvido.com
            </a>
            .
          </P>
        </section>
        <section>
          <H3>11. Glossary</H3>

          <P>
            <strong>Legitimate Interest</strong> means the interest of our
            business in conducting and managing our business to enable us to
            give you the best service/product and most secure experience. We
            make sure we consider and balance any potential impact on you (both
            positive and negative) and your rights before we process your
            personal data for our legitimate interests. We do not use your
            personal data on the basis of legitimate interests for activities
            where our interests are overridden by the impact on you.
          </P>

          <P>
            <strong>Performance of Contract</strong> means processing your data
            where it is necessary for the performance of a contract to which you
            are a party or to take steps at your request before entering into
            such a contract. In respect to Wolvido, that is the performance of
            our agreement with you to make the Services available. We are not
            involved in the processing of your personal data during any
            engagement you enter into with our third party partners or
            integration providers.
          </P>

          <P>
            <strong>Comply with a legal obligation</strong> means processing
            your personal data where it is necessary for compliance with a legal
            obligation.
          </P>
        </section>
      </Container>
    </Layout>
  );
};

export default PrivacyPage;
